import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxAlertsModule, NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { NgxMaskModule } from 'ngx-mask';
import { HttpModule } from '@angular/http';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AppConfig } from './service/app.config';
import { Global } from './global';
import { ApiService } from './service/apiServices';
import { NgxViacepModule, NgxViacepService } from "@brunoc/ngx-viacep";
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NgxKjuaModule } from 'ngx-kjua';
import { AutocompleteOffDirective } from './directivas/autocomplete-off.directive';
import { OnlyLettersDirective } from './directivas/only-letters.directive';
import { HomeComponent } from './screens/home/home.component';
import { ptLocale } from './locales/pt-locale';
import { usLocale } from './locales/us-locale';
export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}


registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AutocompleteOffDirective,
    OnlyLettersDirective    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
    NgxAlertsModule.forRoot(),
    NgxMaskModule.forRoot(),
    SnotifyModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#2196f3',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    NgxViacepModule,
    AppRoutingModule,
    NgxKjuaModule,
    NoopAnimationsModule,
    MatDialogModule
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "pt"
    },    
    NgxAlertsService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    ApiService,
    Global,
    ptLocale,
    usLocale
  ],
  entryComponents: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
