import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser = new BehaviorSubject(undefined);
  authToken = new BehaviorSubject(undefined);
  clientes = new BehaviorSubject(undefined);
  currentClient = new BehaviorSubject(undefined);
  client = new BehaviorSubject(undefined);
  paymentData = new BehaviorSubject(undefined);
  currentCheckout = new BehaviorSubject(undefined);


  constructor(private router: Router) { }

  async loginUser(user) {
    await localStorage.setItem('@LpPoliz:currentUser', JSON.stringify(user));
    await this.currentUser.next(user);
  }

  async saveUser(user) {
    await localStorage.setItem('@LpPoliz:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token)
    await this.currentUser.next(user);
  }


  async logoutUser() {
    await localStorage.clear();
    await this.authToken.next(null);
    await this.currentUser.next(null);
    await this.client.next(null);
    await this.paymentData.next(null);
    this.router.navigate(['login']);
  }

  async logoutUserCheckout() {
    // await this.authToken.next(null);
    await this.currentUser.next(null);
    // await this.client.next(null);
    await this.paymentData.next(null);
    this.router.navigate(['login']);
  }

  async saveClientes(dados) {
    await this.clientes.next(dados);
  }

  async saveCurrentCheckout(dados) {
    await this.currentCheckout.next(dados);
  }

  async saveClient(dados, m) {
    await this.currentClient.next(dados);
    await this.client.next(m);
  }

  async setPayment(dados) {
    await this.paymentData.next(dados);
  }

  async authCheck(user) {
    await localStorage.setItem('@LpPoliz:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token)
    await this.currentUser.next(user);
  }

}
