// global.ts
import { Injectable } from "@angular/core";
@Injectable()
export class usLocale {

    locale = {
        title_login: `POLIZ ANGELS PROGRAM`,
        login_text: `Enter the following data and the special access password (available in your welcome letter) to continue.`,
        text_terms_login: `By continuing, you agree to Poliz's   <span><a href="../assets/docs/POLIZ - CONFIDENTIALITY & SECRECY AGREEMENT.pdf" target="_blank">   Terms of Confidentiality</a></span> and Confidentiality.`,
        btn_concordo_login: `I agree`,

        title_header: `A different way of <span> making politics</span>`,
        sub_title_header: `Poliz is a social media that allows you to rate and demand politicians and city halls so that you can  practice your citizenship in a simple way.`,
        btn_header: `Invest in Poliz`,
        power_hands: `Poliz, the power <br /> of politics <br /> <span> on your hands!</span>`,
        text_power_hands: `Our mission is to stimulate a safe, effective and accessible participation of people on the politics
        debate.`,
        text_power_hands_2: `In the Poliz App, candidates, politicians and city halls can manifest and dialogue.`,
        text_power_hands_3: `Politicians can make better decisions based on the statistics data and acceptance thermometer and
        citizens can practice their citizenship in a safer and more inclusive way.`,
        text_power_hands_4: `Our next step is to find partners that believe in the importance of the dialog to invest in Poliz.`,
        conheca_modelos_btn: `Learn more about our business model
        `,
        aplicativo_poliz: `POLIZ APP`,

        title_avalie_politicos: `Citizen: Rate politicians <br />  and express yourself`,
        text_avalie_politicos: `Citizens can express their needs, opinions and political views about public and political services
        through posts and ratings.`,

        title_interaja_politicos: `Politician: Interact with  <br /> your electors `,
        text_interaja_politicos: `Politicians and candidates have their profile at Poliz e can be viewed and rated by any citizen,
        increasing the connection and the dialogue with the electors.`,

        title_premium_politicos: `Premium politician profile`,
        text_premium_politicos: `With Premium Profile, the politicians can give more information, such as their curriculum, proposals
        and projects, to those who access their profile`,

        text_veja_road_map: `See our complete Technology Roadmap and download the <br /> app to discover all of Poliz's features`,

        button_road_map: `See our Roadmap`,

        button_baixar_app: `Download the app`,

        title_time_poliz: `Poliz team`,

        title_guilherme_neves: `Founding partner`,
        text_guilherme_neves: `I have a degree in international relations and have been working for over 14 years in a multinational
        food company. I was expat to Switzerland, where I have been developing a solid
        and consistent work in the area of human relations ever since. Optimist by nature, I believe in a
        fairer and more equal world. I founded Poliz with one goal: to empower people so they can shape
        their own reality.`,

        title_leandro_bovo: `Cofounder partner`,
        text_leandro_bovo: `I was born in Araras, countryside of São Paulo (SP) and have been experiencing living in different
        cities for the past twenty years, due to the transferences provided by the great multinacional food
        company that I work for. With Poliz, I saw the opportunity to develop an inclusive platform where
        everyone can be heard and express their political beliefs. I decided to embrace this journey as a
        personal challenge to learn and deepen my knowledge on politics.`,

        title_nilber_vittorazzi: `IT / Tecnologia`,
        text_nilber_vittorazzi: `I have a bachelor degree in Information Systems, master in IT, with 16 years of experience
        developing softwares. I’m a specialist in blockchain, infrastructure, datacenter, data security and
        complex algorithms. I also won the SindInfo prize for an order management system with
        georeferencing surveillance.`,

        title_carolina_fuga: `CEO`,
        text_carolina_fuga: `I’m a social scientist and entrepreneur, specialist in startups and social impact business, with more
        than 11 years market experience. I founded 4YOU2 Idiomas and worked with the Nobel Peace
        laureate Muhammad Yunus. I believe that technology and innovation are the fundamental
        foundation to social development.`,

        title_juliana_lira: `UX/UI designer`,
        text_juliana_lira: `I’m a computer engineer and design admirer. I had the great honour of being invited to join the
        FIAPnext technology festival, where I was awarded for my thesis on artificial intelligence. Since 2018
        I’m a UX/UI designer on Poliz, a humanized Startup that provides me daily with new teachings.
        `,

        title_daniela_mendes: `F&C`,
        text_daniela_mendes: `I graduated in administration and accounting sciences, have 20 years’ experience and a fascination
        with the business world! I worked in many sectors and dimensions companies, both national and
        international. To me, justice and balance are essential. Being part of Poliz unites my great passions.`,

        title_mazzuco: `Law Office`,
        text_mazzuco: `We have an office made by professionals with solid experience in business law. We offer legal
        solutions for complex and multidisciplinary problems, with a high standard customer service and
        follow-up of our partners. Our legal approach is focused on strategy and customers’ business goals`,

        title_luccy: `Legal Consultant`,
        text_luccy: `I have a degree in social communication in addition to having experience in the following areas: public relations, advertising and marketing. I have na entrepreneurial spirit and I live creating new businesses. I believe in diversity and contributing to Poliz made perfect sense to my purpose: helping people to show their voice and seek a better environment.`,

        text_see_more: 'see more',

        title_onde_estamos: `WHERE WE ARE`,
        time_line_month_julho: `JULY`,
        time_line_month_janeiro: `JANUARY`,
        time_line_month_agosto: `AUGUST`,
        time_line_month_setembro: `SEPTEMBER`,

        time_line_text_julho: `THE IDEA WAS BORN ALONG WITH THE CREATION OF THE FIRST PROTOTYPES`,
        time_line_text_janeiro: `INTERVIEW WITH USER`,
        time_line_text_janeiro_2: `WEB VERSION TEST`,
        time_line_text_agosto: `BETA VERSION DEVELOPMENT`,
        time_line_text_janeiro_21: `BETA VERSION APP LAUNCHING`,
        time_line_text_setembro_21: `CREATION OF STRATEGIC PLANNING:`,
        time_line_text_setembro_21_1: `- Finance`,
        time_line_text_setembro_21_2: `- Marketing`,
        time_line_text_setembro_21_3: `- Investment funding`,
        title_captacao_investimentos: `CAPTAÇÃO DE INVESTIMENTOS`,

        sub_title_captacao_investimentos: `Now it is the time of expanding!`,
        text_captacao_investimentos: `We are in our first round of investments, known as Seed. The term comes from the verb    to seed,
        meaning to make  the company grow. In the previous stage (pre-seed),  the founder    associates already  invested BRL 250,000.00 with the
        concept development,    team and legal and financial advice
        `,
        title_porque_investir: `WHY INVEST?`,

        text_porque_investir_1: `The power of social media today is undeniable and it is no surprise that the internet has caused
        impact and has had great influence on democracy.`,
        text_porque_investir_2: `In 2018, Jair Bolsonaro won the election race after gaining a considerable number of followers
        during his presidential campaign, even though he has avoided almost every live broadcast national
        debates. Until then, nothing like that has never happened because television represented the main
        political advertising vehicle.`,

        text_porque_investir_3: `Social medias became the most important forum of public discussions. In this process, the politicians started engaging with their voters via online channels instead of regular offline media.`,

        title_bloco_jornal_1: `ELECTION OF BOLSONARO MARKS CHANGE IN POLITICAL MARKETING`,
        title_bloco_jornal_2: `Elections 2020: Brazil breaks record for registered candidates`,

        text_porque_investir_5: "Still didn’t understand how we transform that into business?",
        text_porque_investir_6: `Keep an eye on the following data and notice that there is another important tendency in our favor,
        boosting even more our business potential`,

        title_alguns_numeros: `SOME <br /> NUMBERS`,

        text_mapa_politicos: `politicians`,
        text_mapa_municipios: `municipalities in Brazil`,
        text_mapa_candidatos: `candidates for public office`,
        text_mapa_potenciais: `PROSPECTS`,

        title_produtos_servicos: `PRODUCTS AND SERVICES`,

        title_bloco_produtos_servicos_1: `Premium License for <br />Politicians and Candidates`,
        text_bloco_produtos_servicos_1: `R$ 1548,00 (per person/year)`,
        title_bloco_produtos_servicos_2: `Sale of Reports <br /> «Political Insights»`,
        text_bloco_produtos_servicos_2: `R$ 774,00 / report`,

        title_potencia_receita: `REVENUE POTENTIAL`,
        text_potencial_licenca: `revenue potential with Premium License`,
        text_potencial_reports: `revenue potential with Reports`,
        sub_title_potencia_receita: `Potential of + <span>R$ 1,2 billion</span> revenue/year`,

        title_o_que_faremos_investimentos: `HOW THE INVESTMENTS WILL BE ALLOCATED`,
        sub_title_o_que_faremos_investimentos: `We need to raise BRL 800,000.00 at this stage to begin the operations and to attract users to Poliz.
        This amount is going to be used as cash flow for fix and variable expenses.`,
        text_o_que_faremos_investimentos: `
        The investments  <br /> are going to be allocated  <br /> to fixed personnel  <br /> and variable expenses, divided  <br /> into 3 spheres
       `,
        text_btn_o_que_faremos_investimentos: `Invest in Poliz`,
        title_bloco_atracao: `Atraction`,
        text_bloco_atracao: ` Marketing campaign to   attract new users`,

        title_bloco_website: `IT & infrastructure`,
        text_bloco_website: `Design of new features.`,

        title_bloco_institucional: `Institutional`,
        text_bloco_institucional: `Institutional marketing`,

        title_distribuicao_investimento: `INVESTMENT <br /> DISTRIBUTION`,
        text_distribuicao_investimento: `<br /> we will invest in <br /> Marketing strategies and improvement <br /> of Technology .`,
        legend_distribuicao_investimento_marketing: `Marketing`,
        legend_distribuicao_investimento_tecnologia: `Technology and Infra`,
        legend_distribuicao_investimento_despesas: `Personnel expenses (Administrative and Legal)`,

        title_para_onde_vamos: `Where are we going to?`,
        text_metas_montar_equipe: `Assemble a team`,
        text_metas_acao_mkt: `MKT action to attract new users`,
        text_metas_sistema_seo: `Security system and SEO`,
        text_metas_debates: `Live debates and gamification`,
        text_metas_features: `Features to City Halls and Parties`,
        text_metas_consultoria: `Poliz Consulting and Events`,

        title_plano_marketing: `MARKETING PLAN`,
        sub_title_plano_marketing: `Marketing strategy and attraction of new users`,
        sub_title_plano_marketing_promocao: `1. Promotion:`,
        tex_plano_marketing_promocao: `Like other social medias, Poliz will also offer prizes to the users who uses more it’s net. In this case,
        the prizes will be distributed as the citizens use the net to express themselves and to practice their
        citizenship. The more actions (post, share, debate, rate) the user has in a determined period, the
        more points he will have. The top 5 users with the higher score in the platform will receive cash
        prizes and scolarships worth BRL 1,000.00 each. In order to make people know the prizes offered by
        Poliz, we will run communication campaigns via social medias with boosted posts.
        `,

        sub_title_plano_marketing_gamificacao: `2. Gamification:`,
        tex_plano_marketing_gamificacao: `Our goal is to make the user experience simple and fun. We believe that politics can be seen and
        practiced like that e that is why we are going to make some changes on the app, including
        gamification items to attract users organically. We also predict the designing of games, the aim is to
        make them viral among the young public. Visit the product roadmap to learn more.`,

        sub_title_plano_marketing_polarizacao: `3. Organic polarization:`,
        tex_plano_marketing_polarizacao: `We believe that polarization is not always for the worst. In this case, it will be used to attract people
        with distinct political views to the platform. For example, we are going to show write and left wings 
        associated politicians profiles, inviting them to see and influence the result of players that are
        already popular in the political game.
        `,

        text_baixar_plano: `Here you can download and check out our complete<br />Marketing Plan!`,
        btn_apresentacao_plano: `Marketing Plan`,
        btn_planilha_investimento: `Marketing Investment`,
        btn_visualizar_dre: `View the complete DRE`,
        title_pesperctiva_financeira: `Financial Perspective`,

        sub_title_pesperctiva_financeira: `We count on the expertise of Daniela Mendes who supported us in the preparation of Poliz's Financial Planning.`,
        text_pesperctiva_financeira: `Planning was carried out for the initial 36 months, starting in February 2022.`,


        title_rodada_investimento: `FUNDING <br />ROUND`,
        sub_title_rodada_investimento: `Follow our timeline`,

        title_segment_janeiro: `January/2022`,
        text_segment_janeiro: `Between 10 to 15 angels <br />Investment: 800k and 1,200k<br />Quotas distribuídas: Betwenn 15% and 22,50%<br />Equity: 1,50%/ angel <br />Shares Dilution: Partner Guilherme`,

        title_segment_2024: `2024`,
        text_segment_2024: `10 Sower investors<br />Investment: from 3 to 5 million<br />Shares distributed: 7% to 10%<br />Equity: 0.7% for each investor<br />Dilution of Shares: equally between<br />the partners Leandro and Guilherme (angel investors will not be diluted for a period of 5 years)`,

        title_segment_2027: `2027`,
        text_segment_2027: `Number of investors to be defined<br />Investment: over 10 million<br />Shares distributed: 7% to 10%<br />Equity: to be defined <br />Shares dilution: equally among all partners `,

        title_poliz_angels: `Poliz Angels`,
        sub_title_poilz_angels: `Planning was carried out for the initial 36 months, starting in February 2022`,
        text_data_poliz_angels: `Tuesday, January 25, 2022.`,

        text_captacao_hoje: `Funding today: 200k`,
        text_captaca_minima: `Minimum funding: 800k`,
        text_captacao_maxima: `Maximum uptake: 1200k`,

        title_distribuicao_participacao: `Share of<br />equity interest`,
        title_grafico_atual: `Current<br />Pre-round Poliz Angels`,
        title_grafico_pos_poliz_angels: `Post Round of Poliz Angels<br /> (considering 15 angel investors)`,
        title_grafico_pos_investimento_seed: `Post Round of Investments <br /> Seed`,
        title_grafico_pos_investimento_a: `Post Round of Investments <br /> Series A`,

        title_como_funcionar_poliz_angels: `POLIZ ANGELS: HOW DOES IT WORK?`,
        sub_title_como_funcionar_poliz_angels: `You can be an angel investing in Poliz and contribute to raising the capital needed to start operations.`,
        sub_title_como_funcionar_poliz_angels2: `When signing a contract with Poliz, at the end of the 36-month period, you will be able to choose between receiving your investment with interest (and appreciation above the average market rates) or transforming the amount invested to join the company (and receiving dividends and valuation of shares in the future).`,
        sub_title_como_funcionar_poliz_angels3: `The amount invested will be converted into equity. For example, for the investment in the minimum amount (equivalent to R$ 20,000.00), the equity will be 0.375.`,

        title_table_como_funciona: `Invested Capital`,
        title_table_como_funciona_juros_Anual: `Annual interest`,
        title_table_como_funciona_juros_Mensal: `Monthly Interest`,

        title_table_como_funciona_mes: `MONTH`,
        title_table_como_funciona_base: `BASE`,
        title_table_como_funciona_juros_m: `MONTHLY INTEREST`,
        title_table_como_funciona_juros_c: `ACCUMULATED INTEREST`,
        title_table_como_funciona_salso: `BALANCE`,


        title_table_comparativa: `Comparative table with annual profitability of other portfolios<br />(data collected from Banco Santander Brasil for simple reference):`,
        text_consultado_comparativa: `*Consulted on January 10, 2022`,
        title_table_comparativa_tipo: `TYPE`,
        title_table_comparativa_rentabilidade: `ANNUAL PROFITABILITY`,
        btn_table_comparativa: `At Poliz, your investment yields 15% per year`,

        title_conversao_investimento: `Conversion of investment into equity interest`,
        text_conversao_investimento: `If, at the end of the 36-month period, you choose to join the Poliz company, your capital
         invested will give
         you the right to acquire <br /> shares and you will be included in Poliz's social contract.`,

        sub_title_conversao_investimento: `The invested amount will be <br /> converted into equity of 1.50%`,

        title_valuation: `Valuation: How much is Poliz worth?'`,
        texte_valuation: `    We already have MVP (Minimum Viable Product), team, <br /> business model,
         financial planning and our GO <br /> TO MARKET. We calculate our market value based on the
         <br />
         EBITDA
         (Earnings Before Interest, Taxes, Depreciation and Amortization) <br /> of the year 2024, considering
         1.5X,
         according
         table below:`,

        title_tabela_valuation_previsao: `Previsão EBTIDA 2024`,
        title_tabela_valuation_previsao1: `4 826 961 BRL`,
        title_tabela_valuation_previsao2: `0,375% quote`,
        title_tabela_valuation_previsao3: `Discounted amount for the Poliz Angles program, corresponding to 0.375% quotas`,

        title_governanca_politica: `Corporate governance`,
        text_governanca_politica: ` If, at the end of the 36-month period, you choose to join the Poliz company, your <br />
         invested will give
         you the right to acquire shares and you will be included in the <br/> Poliz social contract.`,

        text_governanca_politica_1: ` Publication of the monthly financial statement, here on the investor's portal, containing all <br /> information in sales, revenue, expenses, etc. With all due transparency.`,

        text_governanca_politica_2: ` Monthly news clipping publication containing information about <br /> users, new customers, marketing actions, articles published in the media, etc.`,

        text_governanca_politica_3: ` Monthly publication of new developments in the app, such as new <br /> functionalities, bug fixes, new versions, etc.`,

        text_governanca_politica_4: ` Quarterly meetings, where you will have the opportunity
         to share your feedback <br /> and strategy suggestions`,

        title_compartilhar: `We would like to share<br /> also some <br /> important information about legal <br />
         and compliance that <br />
          has been carefully <br /> developed for this <br />process with our <br />
         investors.`,
        text_check_contratuais: `Contract Minutes`,
        text_contratuais: `
        Mazzucco e Melo Advogados  <br />  elaborated the contracts that aim  <br /> to protect the company, their
        founders and you, investors.`,
        btn_contratuais: `Read more`,


        text_check_compliance: `Compliance`,
        text_compliance: `We determined some basic precepts so   that you could be a Poliz investor.`,
        btn_compliance: `Leia mais`,

        title_contato: `Let’s talk,`,
        sub_title_contato: `So, ready to invest? We will contact you to clear up any questions you have and to know what you think of Poliz.`,

        texto_enviar_copia: 'Send me a copy ',
        texto_enviar: 'Send',

        text_ligue_para_nos: `Prefer calling`,

        text_polici: `Non-Disclosure Agreement (NDA)`,
        text_terms: 'Terms of Use',

        text_menu_o_app: `The APP`,
        text_menu_nosso_time: `Our team`,
        text_menu_onde_estamos: `Where are we`,
        text_menu_poliz_angels: `Poliz Angels`,
        text_menu_vamos_conversar: `Let's talk`,

        btn_pacto_social: `Our Social pact`

    }

}
