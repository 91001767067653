import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyLetters]'
})
export class OnlyLettersDirective {


  constructor(private _el: ElementRef) {         
  }

  @HostListener('keyup', ['$event']) keyup(event) {
    event.target['value'] = this._el.nativeElement.value.replace(/[0-9]/g, "");
  }


}
