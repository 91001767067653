import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { Global } from 'src/app/global';
import { ptLocale } from 'src/app/locales/pt-locale';
import { usLocale } from 'src/app/locales/us-locale';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var Swiper: any;
declare var $: any;

declare var Chart: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {


  politicos: any = 129284;
  municipios: any = 5568;
  candidatos: any = 557407;
  clientes: any = 557407;
  progress1: any = 50;
  progress2: any = 35;

  progress1m: any = 80;
  progress2m: any = 55;
  progressvalue: any = 862866036;
  progressvalue2: any = 431433018;
  dateTimeNow: any = moment();

  videoEn: any = "https://iframe.videodelivery.net/67e2e67a3bf61b0ce3a57a68c872fdd3?preload=true&controls=true&poster=https%3A%2F%2Fvideodelivery.net%2F67e2e67a3bf61b0ce3a57a68c872fdd3%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false";
  videoPt: any = "https://iframe.videodelivery.net/73271c6705e2df3ee643706b3aa18b11?preload=true&controls=true&poster=https%3A%2F%2Fvideodelivery.net%2F73271c6705e2df3ee643706b3aa18b11%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false";

  scroolDiv: any = "oApp";

  loading: any = false;

  arrayFlags = [
    {
      value: 'BR',
      icon: '../assets/imgs/brasil.svg'
    },
    {
      value: 'US',
      icon: '../assets/imgs/us.svg'
    },
  ];

  arrayTable1 = [
    {
      nome: '+ Receita Líquida de Serviços',
      total2022: '553 759,07',
      total2023: '3 022 319,47',
      total2024: '7 619 918,03',
      mes35: '962 015,08',
      mes36: '1 011 667,47',
      total2025: '1 973 682,56'
    },
    {
      nome: '= LUCRO BRUTO',
      total2022: '553 759,07',
      total2023: '3 022 319,47',
      total2024: '7 619 918,03',
      mes35: '962 015,08',
      mes36: '1 011 667,47',
      total2025: '1 973 682,56'
    },
    {
      nome: '- Despesa com Pessoal',
      total2022: '-537 950,00',
      total2023: '-787 039,00',
      total2024: '-1 578 270,87',
      mes35: '-144 907,41',
      mes36: '-134 907,41',
      total2025: '-279 814,82'
    },
    {
      nome: '- Despesas com Marketing',
      total2022: '-530 000,00',
      total2023: '-125 640,00',
      total2024: '-259 069,68',
      mes35: '-22 258,40',
      mes36: '-22 258,40',
      total2025: '-44 516,81'
    },
    {
      nome: '- Despesas com Atendimento',
      total2022: '-5 500,00',
      total2023: '-6 282,00',
      total2024: '-12 953,48',
      mes35: '-1 112,92',
      mes36: '-1 112,92',
      total2025: '-2 225,84'
    },
    {
      nome: '- Despesa com Infra-Estrutura e TI',
      total2022: '-205 496,00',
      total2023: '-294 637,08',
      total2024: '-506 175,21',
      mes35: '-57 814,07',
      mes36: '-57 814,07',
      total2025: '-115 628,15'
    },
    {
      nome: '- Despesas com Ocupação',
      total2022: '-1 650,00',
      total2023: '-1 884,60',
      total2024: '-234 000,00',
      mes35: '-19 500,00',
      mes36: '-19 500,00',
      total2025: '-39 000,00'
    },
    {
      nome: '- Serviços Especializados',
      total2022: '-164 416,00',
      total2023: '-164 984,78',
      total2024: '-190 830,01',
      mes35: '-16 188,81',
      mes36: '-16 188,81',
      total2025: '-32 377,62'
    },
    {
      nome: '- Despesas Administrativas',
      total2022: '-9 900,00',
      total2023: '-11 307,60',
      total2024: '-11 658,14',
      mes35: '-1 001,63',
      mes36: '-1 001,63',
      total2025: '-2 003,26'
    },
    {
      nome: '- Depreciação e Amortização',
      total2022: '-7 400,00',
      total2023: '-12 800,00',
      total2024: '-17 400,00',
      mes35: '-1 500,00',
      mes36: '-1 500,00',
      total2025: '-3 000,00'
    },
    {
      nome: '= RESULTADO OPERACIONAL',
      total2022: '-908 552,93',
      total2023: '1 617 744,40',
      total2024: '4 809 560,64',
      mes35: '697 731,84',
      mes36: '757 384,23',
      total2025: '1 455 116,06'
    },
    {
      nome: '- Despesa Financeira',
      total2022: '-17 749,18',
      total2023: '-82 749,10',
      total2024: '-203 165,09',
      mes35: '-25 469,87',
      mes36: '-26 766,43',
      total2025: '-52 236,30'
    },
    {
      nome: '= RESULTADO ANTES DOS IMPOSTOS',
      total2022: '-926 302,10',
      total2023: '1 534 995,30',
      total2024: '4 606 395,55',
      mes35: '672 261,97',
      mes36: '730 617,80',
      total2025: '1 402 879,77'
    },
    {
      nome: '= LUCRO / (PREJUÍZO) LÍQUIDO',
      total2022: '-928 676,77',
      total2023: '932 199,96',
      total2024: '3 040 221,06',
      mes35: '443 692,90',
      mes36: '482 207,75',
      total2025: '925 900,65'
    },
    {
      nome: '= LUCRO / (PREJUÍZO) LÍQUIDO ACUMULADO',
      total2022: '-928 676,77',
      total2023: '3 523,18',
      total2024: '3 043 744,25',
      mes35: '3 487 437,15',
      mes36: '3 969 644,89',
      total2025: '3 969 644,89'
    },
  ];

  flagSelected: any = this.arrayFlags[0];
  localeSelected: any = this.pt.locale;
  register: any = {};
  _global: any;
  user: any = {};
  showRegister: any = true;
  constructor(
    private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
    public pt: ptLocale,
    public us: usLocale,
  ) {
    this._global = global;
    var currentUser = localStorage.getItem('@LpPoliz:currentUser');
    if (currentUser) {
      this.user = JSON.parse(currentUser);
      this.showRegister = false;
    } else {
      this.showRegister = true;
      var body = $('body');

      if (!body.hasClass('not-scroll')) {
        body.addClass('not-scroll');
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 2000);
    }
  }

  ngOnInit() {
    this.initJsScripts();
  }

  selectFlag(text: string) {
    if (text) {
      var flag = this.arrayFlags.filter(x => x.value == text);
      if (flag.length > 0) {
        this.flagSelected = flag[0];
        if (this.flagSelected.value == 'BR') {
          this.localeSelected = this.pt.locale;
          let that = this;

          $("iframe").each(function () {
            var src = $(this).attr('src');
            $(this).attr('src', that.videoPt);
          });
        } else if (this.flagSelected.value == 'US') {
          this.localeSelected = this.us.locale;
          let that = this;

          $("iframe").each(function () {
            var src = $(this).attr('src');
            $(this).attr('src', that.videoEn);
          });
        }

      }

    }
  }

  removeBlockScroll() {
    var obj = Object.assign({}, this.register);
    if (this.register == null) {
      return;
    }

    if (this.register.name == null || this.register.name == "") {
      return;
    }
    if (this.register.birthdate == null || this.register.birthdate == "") {
      return;
    }

    let nova_data = obj.birthdate.split('/')[2] + "-" + obj.birthdate.split('/')[1] + "-" + obj.birthdate.split('/')[0];
    if (new Date(nova_data + "T00:00:00").toString() == "Invalid Date") {
      return;
    }


    if (this.register.email == null || this.register.email == "") {
      return;
    }
    if (this.register.password == null || this.register.password == "") {
      return;
    }

    if (this.register.terms == null || this.register.terms == false) {
      return;
    }

    this.register.birthdate = nova_data;

    this.authInvestidor(this.register)

  }

  isVisible() {
    
  }


  initJsScripts() {


    $('#polizAngels').bind('isVisible', this.isVisible);

    let that = this;
    $("iframe").each(function () {
      var src = $(this).attr('src');
      $(this).attr('src', that.videoPt);
    });

    this.scrollTopF();

    var fullHeight = function () {

      $('.js-fullheight').css('height', $(window).height());
      $(window).resize(function () {
        $('.js-fullheight').css('height', $(window).height());
      });

    };
    fullHeight();

    // loader
    var loader = function () {
      setTimeout(function () {
        if ($('#ftco-loader').length > 0) {
          $('#ftco-loader').removeClass('show');
        }
      }, 1);
    };
    loader();

    // Scrollax
    $.Scrollax();


    $('nav .dropdown').hover(function () {
      var $this = $(this);
      // 	 timer;
      // clearTimeout(timer);
      $this.addClass('show');
      $this.find('> a').attr('aria-expanded', true);
      // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').addClass('show');
    }, function () {
      var $this = $(this);
      // timer;
      // timer = setTimeout(function(){
      $this.removeClass('show');
      $this.find('> a').attr('aria-expanded', false);
      // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').removeClass('show');
      // }, 100);
    });


    $('#dropdown04').on('show.bs.dropdown', function () {
    });

    // scroll
    var scrollWindow = function () {
      $(window).scroll(function () {
        var $w = $(this),
          st = $w.scrollTop(),
          navbar = $('.ftco_navbar'),
          imgScroll = $('#img-scroll'),
          imgFixed = $('#img-fixed'),
          btnInvista = $('#btn-invista'),
          locale = $('#locale-select'),
          contact = $('#btn-contact'),
          btnMenu = $('#btn-menu'),

          sd = $('.js-scroll-wrap');

        if (st > 150) {
          if (!navbar.hasClass('scrolled')) {
            navbar.addClass('scrolled');
          }
          if (!imgScroll.hasClass('fixed')) {
            imgScroll.removeClass('fixed-none');
            imgScroll.addClass('fixed');
          }
          if (!btnMenu.hasClass('fixed')) {
            btnMenu.removeClass('fixed-none');
            btnMenu.addClass('fixed');
          }

          if (!locale.hasClass('fixed')) {
            locale.removeClass('fixed-none');
            locale.addClass('fixed');
          }
          if (!contact.hasClass('fixed')) {
            contact.removeClass('fixed-none');
            contact.addClass('fixed');
          }

          if (!imgFixed.hasClass('fixed-none')) {
            imgFixed.addClass('fixed-none');
            imgFixed.removeClass('fixed');
          }

          if (!btnInvista.hasClass('fixed-none')) {
            btnInvista.addClass('fixed-none');
            btnInvista.removeClass('fixed');
          }

        }
        if (st < 150) {
          if (navbar.hasClass('scrolled')) {
            navbar.removeClass('scrolled sleep');
          }
          if (btnMenu.hasClass('fixed')) {
            btnMenu.removeClass('fixed');
            btnMenu.addClass('fixed-none');

          }
          if (imgScroll.hasClass('fixed')) {
            imgScroll.removeClass('fixed');
            imgScroll.addClass('fixed-none');
          }
          if (locale.hasClass('fixed')) {
            locale.removeClass('fixed');
            locale.addClass('fixed-none');

          }
          if (contact.hasClass('fixed')) {
            contact.removeClass('fixed');
            contact.addClass('fixed-none');
          }

          if (imgFixed.hasClass('fixed-none')) {
            imgFixed.removeClass('fixed-none');
            imgFixed.addClass('fixed');
          }

          if (btnInvista.hasClass('fixed-none')) {
            btnInvista.removeClass('fixed-none');
            btnInvista.addClass('fixed');
          }

        }
        if (st > 350) {
          if (!navbar.hasClass('awake')) {
            navbar.addClass('awake');
          }

          if (sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if (st < 350) {
          if (navbar.hasClass('awake')) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if (sd.length > 0) {
            sd.removeClass('sleep');
          }
        }

        //VALIDAR MENU CSS
        var oApp = $('#oApp'),
          nossoTime = $('#nossoTime'),
          ondeEstamos = $('#ondeEstamos'),
          polizAngels = $('#polizAngels'),
          contato = $('#contato');

        if (that.isElementInViewport(oApp)) {
          that.scroolDiv = 'oApp';
        } else if (that.isElementInViewport(nossoTime)) {
          that.scroolDiv = 'nossoTime';
        } else if (that.isElementInViewport(ondeEstamos)) {
          that.scroolDiv = 'ondeEstamos';
        } else if (that.isElementInViewport(contato)) {
          that.scroolDiv = 'contato';
        } else if (that.isElementInViewport(polizAngels)) {
          that.scroolDiv = 'polizAngels';
        }

      });
    };
    scrollWindow();

    var counter = function () {

      $('#section-counter, .ftco-about, .ftco-counter').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          var comma_separator_number_step = $.animateNumber.numberStepFactories.separator('.')
          $('.number').each(function () {
            var $this = $(this),
              num = $this.data('number');
            $this.animateNumber(
              {
                number: num,
                numberStep: comma_separator_number_step
              }, 7000
            );
          });

        }

      }, { offset: '95%' });

    }
    counter();


    var contentWayPoint = function () {
      var i = 0;
      $('.ftco-animate').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          i++;

          $(this.element).addClass('item-animate');
          setTimeout(function () {

            $('body .ftco-animate.item-animate').each(function (k) {
              var el = $(this);
              setTimeout(function () {
                var effect = el.data('animate-effect');
                if (effect === 'fadeIn') {
                  el.addClass('fadeIn ftco-animated');
                } else if (effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft ftco-animated');
                } else if (effect === 'fadeInRight') {
                  el.addClass('fadeInRight ftco-animated');
                } else {
                  el.addClass('fadeInUp ftco-animated');
                }
                el.removeClass('item-animate');
              }, k * 50, 'easeInOutExpo');
            });

          }, 100);

        }

      }, { offset: '95%' });
    };
    contentWayPoint();

    var progress = function () {

      $('#section-receitas,.slider-receita, .progress-slider').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {
          $('.progress-slider').each(function () {
            var $this = $(this),
              num = $this.data('progress');
            for (let index = 0; index < num; index++) {
              $this.css("width", `${num}%`);
            }
          });
        }
      }, { offset: '95%' });

    }
    progress();

    var counterProgress = function () {

      $('#section-receitas,.slider-receita, .progress-slider').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          var comma_separator_number_step = $.animateNumber.numberStepFactories.separator('.')
          $('.dinheiro-progress').each(function () {
            var $this = $(this),
              num = $this.data('value');
            $this.animateNumber(
              {
                number: num,
                numberStep: comma_separator_number_step
              }, 7000
            );
          });

        }

      }, { offset: '95%' });

    }
    counterProgress();


    var loadChart = function () {

      $('#chart,.grafic').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {
          const ctx = $('#myChartInvestment');
          const ctxM = $('#myChartInvestmentM');
          try {

            const data = {
              datasets: [{
                backgroundColor: ["#057CC7", "#5FC1FE", "#044974"],
                data: [36, 14, 50]
              }]
            };
            const config = {
              type: 'pie',
              data: data,
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false
                  }
                }
              },
            };
            new Chart(ctx, config);
            new Chart(ctxM, config);

          } catch (error) {

          }



        }

      }, { offset: '95%' });


      $('#dppsGrafics').waypoint(function (direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {
          const chartAtual = $('#chartAtual');
          const chartPosRodada = $('#chartPosRodada');
          const chartPosInvestimento = $('#chartPosInvestimento');
          const chartPosInvestimentoSerieA = $('#chartPosInvestimentoSerieA');

          const chartAtualM = $('#chartAtualM');
          const chartPosRodadaM = $('#chartPosRodadaM');
          const chartPosInvestimentoM = $('#chartPosInvestimentoM');
          const chartPosInvestimentoSerieAM = $('#chartPosInvestimentoSerieAM');

          try {

            const dataChartAtual = {
              datasets: [{
                backgroundColor: ["#044974", "#F27040"],
                data: [85, 15]
              }]
            };
            const configChartAtual = {
              type: 'pie',
              data: dataChartAtual,
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false
                  }
                }
              },
            };


            const dataChartPosRodada = {
              datasets: [{
                backgroundColor: ["#044974", "#FFA382", "#f27040"],
                data: [62.5, 22.5, 15]

              }]
            };
            const configChartPosRodada = {
              type: 'pie',
              data: dataChartPosRodada,
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false
                  }
                }
              },
            };


            const dataChartPosInvestimento = {
              datasets: [{
                backgroundColor:
                  ["#044974", "#FFA382", "#F27040", "#057CC7"],
                data: [59, 22.5, 11.5, 7]
              }]
            };
            const configChartPosInvestimento = {
              type: 'pie',
              data: dataChartPosInvestimento,
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false
                  }
                }
              },
            };



            const dataChartPosInvestimentoSerieA = {
              datasets: [{
                backgroundColor:
                  ["#044974", "#f27040", "#ffa382", "#057cc7", "#5fc1fe"],
                data: [56.67, 9.17, 20.17, 7, 7]
              }]
            };
            const configChartPosInvestimentoSerieA = {
              type: 'pie',
              data: dataChartPosInvestimentoSerieA,
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false
                  }
                }
              },
            };

            new Chart(chartAtual, configChartAtual);
            new Chart(chartAtualM, configChartAtual);

            new Chart(chartPosRodada, configChartPosRodada);
            new Chart(chartPosRodadaM, configChartPosRodada);

            new Chart(chartPosInvestimento, configChartPosInvestimento);
            new Chart(chartPosInvestimentoM, configChartPosInvestimento);

            new Chart(chartPosInvestimentoSerieA, configChartPosInvestimentoSerieA);
            new Chart(chartPosInvestimentoSerieAM, configChartPosInvestimentoSerieA);

          } catch (error) {

          }
        }
      }, { offset: '95%' });
    }
    loadChart();


  }

  isElementInViewport(el) {
    var rect = el[0].getBoundingClientRect()

    return rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
  }

  scrollTopF() {
    const showOnPx = 200;
    var backToTopButton = $('.back-to-top');
    document.addEventListener("scroll", () => {
      if (this.scrollContainer().scrollTop > showOnPx) {
        backToTopButton.removeClass("hidden");
      } else {
        backToTopButton.addClass("hidden");
      }
    });

    backToTopButton.on("click", this.goToTop);
  }

  scrollContainer = () => {
    return document.documentElement || document.body;
  };

  goToTop = () => {
    document.body.scrollIntoView({
      behavior: "smooth"
    });
    var backToTopButton = $('.back-to-top');
    backToTopButton.addClass("hidden");
  };

  scrollToElement($element): void {
    this.scroolDiv = $element.id;
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  authInvestidor(register) {
    var obj = Object.assign({}, register)
    this.loading = true;
    this.apiService.PostPublic('investor-accesses', obj).then((res) => {
      this.authService.loginUser(res);
      this.user = res;

      var body = $('body');
      var register_section = $('#register-section');

      if (body.hasClass('not-scroll')) {
        body.removeClass('not-scroll');
      }
      if (!register_section.hasClass('no-see-div')) {
        register_section.addClass('no-see-div');
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.alertError({
        text: ``,
        title: 'Erro'
      });
    });
  }

  sendMensage() {
    var obj = {
      "name": this.user.name,
      "email": this.user.email,
      "interest": this.user.interest,
      "message": this.user.message,
      "investor": this.user['@id']
    }
    this.apiService.PostPublic('investor-messages', obj).then((res) => {      
      this.alerts.alertSuccess({
        text: ``,
        title: 'Sucesso'
      });
      this.user.message = "";
      this.user.email = "";
      this.user.name = "";

    }).catch((err) => {
      this.loading = false;
      this.alerts.alertError({
        text: ``,
        title: 'Erro'
      });
    });
  }
}
