// global.ts
import { Injectable } from "@angular/core";
@Injectable()
export class ptLocale {

    locale = {
        title_login: `PROGRAMA POLIZ ANGELS`,
        login_text: `Insira os dados a seguir e a senha de acesso especial (disponível na sua carta de boas-vindas) para continuar.`,
        text_terms_login: `Ao continuar, você concorda com os <span><a href="../assets/docs/CONTRATO DE CONFIDENCIALDIADE INVESTIDORES POLIZ.pdf" target="_blank">   Termos de    Confidencialidade</a></span> e Sigilo da Poliz.`,
        btn_concordo_login: `Concordo`,

        title_header: `Um jeito diferente de <span>fazer política</span>`,
        sub_title_header: `A Poliz é uma rede social que permite avaliar e cobrar políticos  e prefeituras, para que você exerça sua cidadania de forma simples.`,
        btn_header: `Invista na Poliz`,
        power_hands: ` Poliz, o poder  da política <br/> <span>nas suas mãos!</span>`,
        text_power_hands: `Nossa missão é estimular a participação da população no debate político de forma segura, efetiva e acessível`,
        text_power_hands_2: `No aplicativo da Poliz cidadãos, candidatos, políticos e prefeituras podem se manifestar e dialogar.`,
        text_power_hands_3: `Políticos podem tomar melhores decisões com base nos dados estatísticos e termômetro de aceitação e cidadãos podem exercer de forma mais inclusiva e segura sua cidadania.`,
        text_power_hands_4: `Nosso próximo passo é encontrar parceiros que acreditem na importância do diálogo e invistam na Poliz.`,
        conheca_modelos_btn: `Conheça mais sobre o nosso modelo de negócios`,
        aplicativo_poliz: `APLICATIVO POLIZ`,
        title_avalie_politicos: `Cidadão: Avalie políticos  e se expresse`,
        text_avalie_politicos: `Cidadãos podem expressar suas necessidades, opiniões e visões políticas sobre serviços públicos e políticos através de postagens e avaliações.`,
        title_interaja_politicos: `Político: Interaja com  seus eleitores `,
        text_interaja_politicos: `Políticos e candidatos tem seu perfil na Poliz e podem ser visualizados e avaliados por qualquer cidadão, aumentando a conexão e o diálogo com eleitores.`,
        title_premium_politicos: `Perfil Premium  para Políticos`,
        text_premium_politicos: `No Perfil Premium, o político pode apresentar mais informações como currículo, propostas e projetos àqueles que acessarem seu perfil. Além disso recebem relatórios e análises estatíticas de aceitação dos usuários.`,
        text_veja_road_map: `Veja nosso Roadmap de Tecnologia completo e baixe o app  para conhecer todas as funcionalidade da Poliz.`,
        button_road_map: `Veja nosso Roadmap`,
        button_baixar_app: `Baixar o app`,
        title_time_poliz: `Time da Poliz`,
        title_guilherme_neves: `Sócio Fundador`,
        text_guilherme_neves: " Sou formado em relações internacionais e trabalho, há mais de 14 anos, em uma empresa multinacional do ramo de alimentos. Fui expatriado para a Suíça e venho desenvolvendo um trabalho sólido e consistente na área de relações humanas, desde então. Otimista por natureza, acredito em um mundo mais igualitário e justo. Fundei a Poliz com um propósito: empoderar pessoas para que elas possam transformar a sua própria realidade.",
        title_leandro_bovo: `Sócio cofundador`,
        text_leandro_bovo: "Nasci em Araras interior de SP, vivo há 20 anos a experiência de morar em diferentes cidades devido às transferências proporcionadas pela gigante multinacional de alimentos na qual trabalho. Na Poliz, vi a oportunidade de desenvolver uma plataforma inclusiva onde todos podem ter voz e expressar suas visões políticas. Decidi embarcar nessa jornada por um desafio pessoal de aprender e me aprofundar na política.",
        title_nilber_vittorazzi: `IT / Tecnologia`,
        text_nilber_vittorazzi: "Sou bacharel em sistemas de informação, mestre em TI, com 16 anos de experiência em desenvolvimento de softwares. Me especializei em blockchain, infraestrutura, datacenter, segurança de dados e algoritmos complexos. Sou também ganhador do prêmio SindInfo com um sistema de gerência de pedidos, com monitoramento por georreferenciamento.",
        title_carolina_fuga: `CEO`,
        text_carolina_fuga: "Sou Cientista Social e empreendedora, especialista em startups e negócios de impacto social, com mais de 11 anos de experiência na área. Fundei a 4YOU2 Idiomas e trabalhei com o laureado Nobel da Paz Muhammad Yunus. Acredito que tecnologia e inovação são pilares fundamentais para desenvolvimento social.",
        title_juliana_lira: `UX/UI designer`,
        text_juliana_lira: "Sou engenheira da computação e amante do design. Tive o imenso prazer de ser convidada a participar do evento de tecnologia FIAPnext, onde fui premiada por minha dissertação sobre inteligência artificial. Desde 2018 sou UX/UI designer na POLIZ, uma startup humanizada que me proporciona diariamente novos aprendizados.",
        title_daniela_mendes: `F&C`,
        text_daniela_mendes: "Me formei em administração e ciências contábeis, tenho mais de 20 anos de experiência e sou apaixonada pelo mundo dos negócios! Atuei em empresas de diversos setores e portes, nacionais e estrangeiras. Justiça e equilíbrio são fundamentais para mim. Poder fazer parte da Poliz une minhas grandes paixões.",
        title_mazzuco: `Escritório de advocacia`,
        text_mazzuco: "Nosso escritório é composto por profissionais com sólida experiência em direito empresarial. Oferecemos soluções jurídicas para problemas complexos e multidisciplinares, com elevado padrão de atendimento e acompanhamento dos sócios. Nossa abordagem jurídica é focada em estratégia e metas comerciais dos clientes.",
        title_luccy: `Consultora de Marketing`,
        text_luccy: `Sou formada em Comunicação Social e tenho experiência com relações públicas, publicidade e marketing. Tenho espírito empreendedor e vivo criando novos negócios. Acredito na diversidade e contribuir com a Poliz fez todo o sentido para o meu propósito: Ajudar as pessoas a terem voz e buscarem um ambiente melhor.`,
        text_see_more: 'saiba mais',
        title_onde_estamos: `ONDE ESTAMOS`,
        time_line_month_julho: `JULHO`,
        time_line_month_janeiro: `JANEIRO`,
        time_line_month_agosto: `AGOSTO`,
        time_line_month_setembro: `SETEMBRO`,
        time_line_text_julho: `NASCIMENTO DA IDEIA E CRIAÇÃO DOS PRIMEIROS PROTÓTIPOS`,
        time_line_text_janeiro: `ENTREVISTA COM USUÁRIO`,
        time_line_text_janeiro_2: `TESTES NAS REDES SOCIAIS E NA VERSÃO WEB`,
        time_line_text_agosto: `DESENVOLVIMENTO VERSÃO BETA`,
        time_line_text_janeiro_21: `LANÇAMENTO DO APLICATIVO VERSÃO BETA`,
        time_line_text_setembro_21: `CRIAÇÃO DO PLANEJAMENTO ESTRATÉGICO:`,
        time_line_text_setembro_21_1: `- Finanças`,
        time_line_text_setembro_21_2: `- Marketing`,
        time_line_text_setembro_21_3: `- Captação de investimentos`,
        title_captacao_investimentos: `CAPTAÇÃO DE INVESTIMENTOS`,
        sub_title_captacao_investimentos: `Agora é o momento de expandir!`,
        text_captacao_investimentos: `Estamos abrindo nossa primeira rodada de investimos no Programa Poliz  Angels. Os sócios fundadores já investiram o montante de R$250.000,00  para o desenvolvimento do conceito, equipe, consultoria jurídica e  financeira!`,
        title_porque_investir: `POR QUE INVESTIR?`,
        text_porque_investir_1: `É inegável o poder de impacto das redes sociais atualmente e e não é novidade que a internet tem trazido impactos e grande poder de influência também para democracia.`,
        text_porque_investir_2: `Em 2018, Jair Bolsonaro venceu as eleições após atingir grande número de seguidores durante a campanha presidencial, mesmo não participando de muitos debates transmitidos em rede nacional. Até então, isso nunca havia ocorrido, já que a televisão sempre representou o principal veículo de propaganda eleitoral.`,
        text_porque_investir_3: `As redes sociais transformam-se no principal fórum das discussões públicas. Nesse processo, os candidatos estão passando a se relacionar com seus eleitores por meios de comunicação on-line e não mais pelas mídias tradicionais.`,
        text_porque_investir_4: `As redes sociais transformam-se no principal fórum das discussões públicas. Nesse processo, os candidatos estão passando a se relacionar com seus eleitores por intermédio dos meios de comunicação digital e não mais pelos partidos.`,
        title_bloco_jornal_1: `ELEIÇÃO DE BOLSONARO MARCA MUDANÇA NO MARKETING POLÍTICO`,
        title_bloco_jornal_2: `Eleições 2020: Brasil bate record de candidatos inscritos`,
        text_porque_investir_5: `Ainda não entendeu como podemos transformar isso em negócio?`,
        text_porque_investir_6: `Fique atento aos dados que vem a seguir e perceba que há uma outra tendência importante a nosso favor alavancando ainda mais o potencial do nosso negócio.`,
        title_alguns_numeros: `ALGUNS  NÚMEROS`,
        text_mapa_politicos: `políticos`,
        text_mapa_municipios: `municípios no Brasil`,
        text_mapa_candidatos: `candidatos a cargos públicos`,
        text_mapa_potenciais: `POTENCIAIS CLIENTES`,
        title_produtos_servicos: `Produtos e Serviços`,
        title_bloco_produtos_servicos_1: `Licença Premium para Políticos e Candidatos`,
        text_bloco_produtos_servicos_1: `R$ 1548,00 (por pessoa/ano)`,
        title_bloco_produtos_servicos_2: `Venda de Relatórios  «Political Insights»`,
        text_bloco_produtos_servicos_2: `R$ 774,00 / relatório`,
        title_potencia_receita: `POTENCIAL DE RECEITA`,
        text_potencial_licenca: `potencial receita com Licença Premium`,
        text_potencial_reports: `potencial receita com Reports`,
        sub_title_potencia_receita: `Potencial de + <span>R$ 1,2 bilhão</span> de receita/ano`,
        title_o_que_faremos_investimentos: `O QUE FAREMOS COM O INVESTIMENTO`,
        sub_title_o_que_faremos_investimentos: `Precisamos captar entre BRL 800.000,00 e BRL 1.200.000,00  para iniciar as operações e atrair usuários para a Poliz. Esse valor será utilizado como fluxo de caixa para as despesas fixas e variáveis.`,
        text_o_que_faremos_investimentos: `Os investimentos  serão alocados em  gastos fixos com  pessoal e variáveis, divididos em 3 esferas`,
        text_btn_o_que_faremos_investimentos: `Invista na Poliz`,
        title_bloco_atracao: `Marketing`,
        text_bloco_atracao: ` Campanha de marketing para  atração de novos usuários`,
        title_bloco_website: `Tecnologia e Infra-estrutura`,
        text_bloco_website: `Desenvolvimento de novas funcionalidades`,
        title_bloco_institucional: `Despesas com pessoal, Administrativo e jurídico`,
        text_bloco_institucional: `Marketing institucional`,

        title_distribuicao_investimento: `DISTRIBUIÇÃO DOS  INVESTIMENTOS`,
        text_distribuicao_investimento: ` Nessa rodada de captação,  investiremos principalmente em estratégias de Marketing e melhoria da Tecnologia.`,
        legend_distribuicao_investimento_marketing: `Marketing`,
        legend_distribuicao_investimento_tecnologia: `Tecnologia e Infra-estrutura`,
        legend_distribuicao_investimento_despesas: `Despesas com pessoal (Administrativo e jurídico)`,

        title_para_onde_vamos: `Para onde vamos?`,
        text_metas_montar_equipe: `Montar equipe Poliz`,
        text_metas_acao_mkt: `Ação de MKT para atrair novos usuários`,
        text_metas_sistema_seo: `Sistema de segurança e SEO`,
        text_metas_debates: `Debates ao vivo e gamificação`,
        text_metas_features: `Features para Prefeituras e Partidos`,
        text_metas_consultoria: `Consultoria e Eventos Poliz`,

        title_plano_marketing: `PLANO DE MARKETING`,
        sub_title_plano_marketing: `Estratégia de Marketing e atração de novos usuários`,
        sub_title_plano_marketing_promocao: `1. Promoção:`,
        tex_plano_marketing_promocao: `Na Poliz os usuários mais ativos receberão premiações. Quanto mais posts,        compartilhamentos,        debates e avaliações um usuário tiver na Poliz, mais pontos terá. Os top 5 usuários com        maior pontuação na plataforma receberão prêmios em dinheiro e bolsa de estudos. Faremos        campanhas de comunicação via redes sociais com posts impulsionados, para divulgar as        premiações.`,

        sub_title_plano_marketing_gamificacao: `2. Gamificação:`,
        tex_plano_marketing_gamificacao: `Acreditamos que política pode ser divertida e por isso, lançaremos itens de gamificação        para        atrair usuários de forma orgânica. Também temos previsto a criação de jogos com o        objetivo        de viralizar o aplicativo nas redes entre o público mais jovem. Visite o roadmap do        produto        para conhecer mais.`,

        sub_title_plano_marketing_polarizacao: `3. Polarização orgânica:`,
        tex_plano_marketing_polarizacao: `Acreditamos que nem sempre a polarização é negativa. Nesse caso, ela será utilizada para 
        atrair as pessoas de distintas visões políticas para a plataforma. Apresentaremos aos 
        usuários o perfil de políticos associados à esquerda e à direita convidando-os para visualizar e influenciar os 
        resultados de figuras já populares no jogo político.`,

        text_baixar_plano: `Aqui você pode baixar e conferir o nosso Planejamento de Marketing completo!`,
        btn_apresentacao_plano: `Apresentação`,
        btn_planilha_investimento: `Planilha de investimento`,
        btn_visualizar_dre: `Visualizar a DRE completa`,

        title_pesperctiva_financeira: ` PLANEJAMENTO FINANCEIRO`,
        sub_title_pesperctiva_financeira: `Contamos com a expertise da Daniela Mendes que liderou a elaboração do Planejamento Financeiro da Poliz.`,
        text_pesperctiva_financeira: `O planejamento foi realizado para os 36 meses iniciais, a partir de Fevereiro de 2022.`,


        title_rodada_investimento: `RODADA DE INVESTIMENTOS`,
        sub_title_rodada_investimento: `Acompanhe nossa linha do tempo  para o processo de captação de  investimentos.`,

        title_segment_janeiro: `Janeiro/2022`,
        text_segment_janeiro: `Entre 10 a 15 anjos Investimento entre: 800k e 1.200kQuotas distribuídas:        Entre        15% e        22,50%Equity: 1,50% para cada anjoDiluição das Quotas: Sócio Guilherme`,

        title_segment_2024: `2024`,
        text_segment_2024: `10 Investidores sowers (semeadores)Investimento: de 3 a 5 milhõesAções        distribuídas:        7% a 10%Equity: 0,7% para cada investidorDiluição das Quotas: igualmente        entreos sócios Leandro e Guilherme (investidores anjos não serão diluídos pelo        prazo        de 5        anos)`,

        title_segment_2027: `2027`,
        text_segment_2027: `Número de investidores à ser definidoInvestimento: acima de 10        milhõesAções        distribuídas: 7% a 10%Equity: à ser definido Diluição das Quotas:        igualmente        entre        todos os sócios`,

        title_poliz_angels: `Poliz Angels`,
        sub_title_poilz_angels: `O planejamento foi realizado para os 36 meses iniciais, a partir de Fevereiro de 2022`,
        text_data_poliz_angels: `Terça-feira, 25 de Janeiro de 2022.`,

        text_captacao_hoje: `Captação hoje: 200k`,
        text_captaca_minima: `Captação mínima: 800k`,
        text_captacao_maxima: `Captação máxima: 1200k`,

        title_distribuicao_participacao: `Distribuição da participação societária`,
        title_grafico_atual: `Atual Pré-rodada Poliz Angels`,
        title_grafico_pos_poliz_angels: `Pós Rodada Poliz Angels (considerando 15 investidores-anjos)`,
        title_grafico_pos_investimento_seed: `Pós rodada de Investimentos  Seed`,
        title_grafico_pos_investimento_a: `Pós Rodada de Investimentos  Series A`,

        title_como_funcionar_poliz_angels: `POLIZ ANGELS: COMO FUNCIONA?`,
        sub_title_como_funcionar_poliz_angels: `Você pode ser um anjo investindo na Poliz e contribuir para o levantamento do capital necessário para o início das operações.`,
        sub_title_como_funcionar_poliz_angels2: `Ao firmar contrato com a Poliz, ao final do período de 36 meses, você poderá escolher        entre        receber seu investimento com juros (e valorização acima das taxas médias de mercado) ou transformar o valor        investido para ingressar na sociedade (e receber dividendos e valorização das ações no futuro).`,
        sub_title_como_funcionar_poliz_angels3: `Valor mínimo investido por cada Anjo é R$20.000,00`,

        title_table_como_funciona: `Capital Investido`,
        title_table_como_funciona_juros_Anual: `Juros Anual`,
        title_table_como_funciona_juros_Mensal: `Juros Mensal`,

        title_table_como_funciona_mes: `MÊS`,
        title_table_como_funciona_base: `BASE`,
        title_table_como_funciona_juros_m: `JUROS MENSAL`,
        title_table_como_funciona_juros_c: `JUROS ACUMULADO`,
        title_table_como_funciona_salso: `SALDO`,


        title_table_comparativa: `Tabela comparativa com rentabilidade anual de outras carteiras(dados coletados do Banco Santander Brasil para simples referência)*:`,
        text_consultado_comparativa: `*Consultado em 10 de Janeiro de 2022`,
        title_table_comparativa_tipo: `TIPO`,
        title_table_comparativa_rentabilidade: `RENTABILIDADE ANUAL`,
        btn_table_comparativa: `Na Poliz seu investimento rende 15% ao ano`,

        title_conversao_investimento: `Conversão do investimento em participação societária`,
        text_conversao_investimento: `Se, ao final do período de 36 meses você escolher por integrar à  sociedade Poliz, seu capital
        investido dará
        a você o direito de aquisição de  quotas e você será incluído no Contrato Social da Poliz.`,

        sub_title_conversao_investimento: `O montante investido poderá ser convertido em equity. Por exemplo, para o investimento no valor mínimo (o equivalente a R$ 20.000,00), o equity será de 0,375.`,

        title_valuation: `Valuation: Quanto vale a Poliz?`,
        texte_valuation: `    Como você sabe, já temos um MVP (Minimum Viable Product), equipe,  modelo de negócios bem
        definido,
        planejamento financeiro e nosso GO  TO MARKET. Calculamos nosso valor de mercado com om base no
        
        EBITDA
        (Earnings Before Interest, Taxes, Depreciation and Amortization)  do ano de 2024, considerando
        1,5X,
        conforme
        tabela abaixo:`,

        title_tabela_valuation_previsao: `Previsão EBTIDA 2024`,
        title_tabela_valuation_previsao1: `4 826 961 BRL`,
        title_tabela_valuation_previsao2: `Valor de 0,375% de quotas`,
        title_tabela_valuation_previsao3: `Valor com desconto para o programa Poliz Angels, correspondente a 0,375% de quotas`,

        title_governanca_politica: `Governança corporativa`,
        text_governanca_politica: ` Se, ao final do período de 36 meses você escolher por integrar à sociedade Poliz, seu  capital
        investido dará
        a você o direito de aquisição de quotas e você será incluído no  Contrato Social da Poliz.`,

        text_governanca_politica_1: `   Publicação do balanço financeiro mensal, aqui no portal do investidor, contendo todas  as
        informações
        de
        vendas, receita, despesas etc. Com toda a transparência de vida.`,

        text_governanca_politica_2: `    Publicação mensal de clipping de notícias contendo informações sobre número de  usuários, novos
        clientes,
        ações de Marketing, matérias publicadas na mídia etc.`,

        text_governanca_politica_3: `  Publicação mensal das novidades no desenvolvimento do aplicativo, como novas  funcionalidades,
        correções de
        Bugs, novas versões etc.`,

        text_governanca_politica_4: ` Reuniões trimestrais, onde você terá a oportunidade
        de
        compartilhar seus feedbacks,  sugestões de estratégias
        e adaptações na execução entre outros temas.`,

        title_compartilhar: `Gostaríamos de compartilhar  também algumas informações  importantes que estamos 
        chamando de Segurança 
        Jurídica e foi cuidadosamente  desenvolvido para esse processo com nossos 
        investidores.`,
        text_check_contratuais: `Minutas contratuais`,
        text_contratuais: `  O escritório Mazzucco e Mello Advogados  elaborou os contratos que visam proteger a 
        empresa, seus
        fundadores e vocês, investidores`,
        btn_contratuais: `Leia mais`,


        text_check_compliance: `Compliance`,
        text_compliance: `  Determinamos alguns preceitos básicos para  que você possa ser um investidor da Poliz`,
        btn_compliance: `Leia mais`,

        title_contato: `Vamos conversar, `,
        sub_title_contato: `E aí, pronto para investir? Entraremos em contato para tirar suas dúvidas e
        saber o que você achou da Poliz!`,

        texto_enviar_copia: 'Envie-me uma cópia',
        texto_enviar: 'Enviar',

        text_ligue_para_nos: `Ou ligue para nós`,

        text_polici: `Termos de Confidencialidade`,
        text_terms: 'Termos de Uso',

        text_menu_o_app: `O APP`,
        text_menu_nosso_time: `Nosso time`,
        text_menu_onde_estamos: `Onde estamos`,
        text_menu_poliz_angels: `Poliz Angels`,
        text_menu_vamos_conversar: `Vamos conversar`,

        btn_pacto_social: `Nosso Pacto Social`

    }

}
